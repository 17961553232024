<template>
  <div class="create-holiday">
    <v-dialog v-model="isDialogVisible" persistent width="640px">
      <v-card>
        <v-card-title class="amber"><span class="headline">Create Holiday</span></v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col sm="6">
                  <v-text-field v-model="name" :rules="nameRules" required label="Name"></v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-menu
                    ref="DateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormated"
                        readonly
                        :rules="dateRules"
                        required
                        label="Date"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" :min="nowDate" no-title @input="dateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn text color="warning" :disabled="!valid" @click="create">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'create-holiday',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    holidays: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      valid: false,
      name: '',
      nameRules: [(v) => !!v || 'Name is required'],
      date: '',
      nowDate: new Date().toISOString().slice(0, 10),
      dateRules: [(v) => !!v || 'Date is required', (v) => !this.checkDate(v) || 'This holiday has already been added'],
      dateMenu: false,
      dateExists: false,
    };
  },
  computed: {
    isDialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('dialog-state-change', value);
      },
    },
    dateFormated: {
      get() {
        return this.date ? this.$moment(this.date).format('L') : '';
      },
      set() {},
    },
  },
  methods: {
    async create() {
      this.user = await axios
        .post(`${this.$store.state.apiURL}/createHoliday`, {
          name: this.name,
          date: this.date,
        })
        .then((res) => {
          console.log('Create holiday response: ', res);
          this.$store.dispatch('holidays/getHolidays');
        });
      this.$store.dispatch('snackbar/showSnack', { snackbar: true, message: `${this.name} created!` });
      this.cancel();
    },
    cancel() {
      this.$refs.form.reset();
      this.isDialogVisible = false;
    },
    checkDate(date) {
      return this.holidays.some((holiday) => this.$moment(holiday.date).format('L') === date);
    },
  },
  watch: {},
};
</script>

<style></style>
