<template>
  <div class="users">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="3" class="ml-auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search this list..." single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="holidays"
          :loading="loading"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.day`]="{ item }">
            {{ item.date | formatDay }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | formatDate }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editHoliday(item)">mdi-pencil</v-icon>
            <v-icon small @click="onDeleteHoliday(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-btn bottom color="amber" fab fixed right @click="onCreateHoliday">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <CreateHoliday :showDialog="dialog" :holidays="holidays" @dialog-state-change="onDialogStateChange" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import CreateHoliday from '@/components/CreateHoliday.vue';

export default {
  name: 'Holidays',
  components: { CreateHoliday },
  data() {
    return {
      pageName: 'Holidays',
      dialog: false,
      search: null,
    };
  },
  computed: {
    ...mapGetters('holidays', { holidays: 'getHolidays', loading: 'getLoading' }),
    headers() {
      if (!this.isSystemAdmin) {
        return [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Day',
            value: 'day',
          },
          {
            text: 'Date',
            value: 'date',
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Day',
            value: 'day',
          },
          {
            text: 'Date',
            value: 'date',
          },
        ];
      }
    },
  },
  created() {
    this.$store.dispatch('holidays/getHolidays');
  },
  methods: {
    onDialogStateChange(state) {
      this.dialog = state;
    },
    onCreateHoliday() {
      this.dialog = true;
    },
    onDeleteHoliday(holiday) {
      axios.post('https://func-gcas-dev.azurewebsites.net/api/deleteHoliday', holiday).then((res) => {
        console.log('Delete holiday response: ', res);
        this.$store.dispatch('holidays/getHolidays');
      });
    },
    isSystemAdmin() {
      return this.$store.state.accounts.currentAccount.role === 'System Admin';
    },
  },
};
</script>
